<template>
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">



    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info">
            <a href="/" class="logo d-flex align-items-center">
              <img src="../assets/logo_sent_ge.png" class="logo-img-src-height">

            </a>
            <p>
              პროფესიონალური ფინანსური ხელსაწყოების პლატფორმა
              მცირე ბიზნესებისთვისა და სტარტაპისთვის.
            </p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>სწრაფი ლინკები</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="#">მთავარი</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">ჩვენს შესახებ</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">პაკეტები</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">წესები და პირობები</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">უსაფრთხოება</a></li>
            </ul>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>ჩვენი სერვისები</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="#">ინვოისების მართვა</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">ინტეგრაციები</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>დაგვიკავშირდით</h4>
            <p>

            </p>

          </div>

        </div>
      </div>
    </div>

  </footer><!-- End Footer -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>