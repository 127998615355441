<template>
  <div>
    <Header/>
    <Slider/>
    <main id="main">


      <!-- ======= Features Section ======= -->
      <section id="features" class="features">

        <div class="container" data-aos="fade-up">



          <!-- Feature Icons -->
          <div class="row feature-icons" data-aos="fade-up">
            <h3>თვისებები</h3>

            <div class="row">

              <div class="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                <img src="assets/img/features-3.png" class="img-fluid p-4" alt="">
              </div>

              <div class="col-xl-8 d-flex content">
                <div class="row align-self-center gy-4">

                  <div class="col-md-6 icon-box" data-aos="fade-up">
                    <i class="ri-line-chart-line"></i>
                    <div>
                      <h4>დეშბორდი</h4>
                      <p>თვალი ადევნე შენს ყველა ფინანსურ მაჩვენებელს ერთ სივრცეში</p>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                    <i class="ri-stack-line"></i>
                    <div>
                      <h4>ინვოისები</h4>
                      <p>მოიგე შენი კლიენტების გული დახვეწილი ინვოისითა და სწრაფი გადახდებით</p>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <i class="ri-brush-4-line"></i>
                    <div>
                      <h4>კლიენტები</h4>
                      <p>შექმენი კლიენტების სია, აკონტროლე მათი აქტივობა და მარტივად გააგზავნე ინვოისები - სრული ინფორმაცია შენს ხელშია</p>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <i class="ri-magic-line"></i>
                    <div>
                      <h4>პროდუქტები</h4>
                      <p>დაამატე შენი პროდუქცია და მარტივად მიუთითე ის ყოველი ინვოისის გაგზავნისას!</p>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                    <i class="ri-command-line"></i>
                    <div>
                      <h4>ინტეგრაციები</h4>
                      <p>ისარგებლე საიტის ინტეგრირებული ფუნქციებით და კიდევ უფრო გაიმარტივე ინვოისების გაგზავნის პროცესი</p>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                    <i class="ri-radar-line"></i>
                    <div>
                      <h4>ელ.კომერცია</h4>
                      <p>ისარგებლე ელ.კომერციის  ინტეგრაციებით და კიდევ უფრო გაიმარტივე ინვოისების გაგზავნის პროცესი</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div><!-- End Feature Icons -->

        </div>

      </section><!-- End Features Section -->


      <!-- ======= Pricing Section ======= -->
      <section id="pricing" class="pricing">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <p>პაკეტები</p>
          </header>

          <div class="row gy-4" data-aos="fade-left">

            <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="box">
                <h3 style="color: #07d5c0;">უფასო</h3>
                <div class="price"><sup>₾</sup>0<span> / mo</span></div>
                <img src="assets/img/pricing-free.png" class="img-fluid" alt="">
                <ul>
                  <li>7 ინვოისი</li>
                  <li>7 კლიენტი</li>
                  <li>1 მომხმარებელი</li>
                  <li>რეპორტი</li>
                  <li>1 ვალუტა</li>
                  <li class="na">შეხსენებები</li>
                  <li  class="na">განმეორებითი ინვოისები</li>
                  <li  class="na">API ინტეგრაციები</li>
                  <li class="na">Email ინტეგრაციები</li>
                </ul>
                <a href="#" class="btn-buy">ყიდვა</a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
              <div class="box">
                <span class="featured">პოპულარული</span>
                <h3 style="color: #65c600;">სტარტაპი</h3>
                <div class="price"><sup>₾</sup>20<span> / თვე</span></div>
                <img src="assets/img/pricing-starter.png" class="img-fluid" alt="">
                <ul>
                  <li>20 ინვოისი</li>
                  <li>20 კლიენტი</li>
                  <li>4 მომხმარებელი</li>
                  <li>რეპორტი</li>
                  <li>3 ვალუტა</li>
                  <li >შეხსენებები</li>
                  <li  class="na">განმეორებითი ინვოისები</li>
                  <li  class="na">API ინტეგრაციები</li>
                  <li class="na">Email ინტეგრაციები</li>
                </ul>
                <a href="#" class="btn-buy">ყიდვა</a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
              <div class="box">
                <h3 style="color: #ff901c;">ბიზნესი</h3>
                <div class="price"><sup>₾</sup>50<span> / თვე</span></div>
                <img src="assets/img/pricing-business.png" class="img-fluid" alt="">
                <ul>
                  <li>ულიმიტო ინვოისი</li>
                  <li>ულიმიტო კლიენტი</li>
                  <li>ულიმიტო მომხმარებელი</li>
                  <li>რეპორტი</li>
                  <li>ულიმიტო ვალუტა</li>
                  <li>შეხსენებები</li>
                  <li>განმეორებითი ინვოისები</li>
                  <li>API ინტეგრაციები</li>
                  <li>Email ინტეგრაციები</li>
                </ul>
                <a href="#" class="btn-buy">ყიდვა</a>
              </div>
            </div>

          </div>

        </div>

      </section><!-- End Pricing Section -->



      <!-- ======= Clients Section ======= -->
      <section id="clients" class="clients">

        <div class="container" data-aos="fade-up">

          <header class="section-header">

            <p>კლიენტები</p>
          </header>

          <div class="clients-slider swiper">
            <div class="swiper-wrapper align-items-center">
              <div class="swiper-slide"  v-for="(item, index) in responsepartnerdata">
                <img v-bind:src="item.logo"  class="img-fluid" alt="">
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>

      </section><!-- End Clients Section -->

      <!-- ======= Recent Blog Posts Section ======= -->
      <section id="recent-blog-posts" class="recent-blog-posts">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <p>სიახლეები</p>
          </header>

          <div class="row">

            <div class="col-lg-4"  v-for="(item, index) in responseblogdata">
              <div class="post-box">
                <div class="post-img">
                  <img v-bind:src="item.picture" class="img-fluid" alt="">
                </div>
                <span class="post-date">{{ item.created_at }}</span>
                <h3 class="post-title">{{ item.title }}</h3>
                <a v-bind:href="'/blog/'+item.id" class="readmore stretched-link mt-auto"><span>სრულად ნახვა</span><i class="bi bi-arrow-right"></i></a>
              </div>
            </div>

          </div>

        </div>


      </section><!-- End Recent Blog Posts Section -->

      <!-- ======= Contact Section ======= -->
      <section id="contact" class="contact">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <p>დაგვიკავშირდით</p>
          </header>

          <div class="row gy-4">

            <div class="col-lg-6">

              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-geo-alt"></i>
                    <h3>მისამართი</h3>
                    <p>ქ.თბილისი,<br>ე.მინდელის 12</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-telephone"></i>
                    <h3>ტელეფონი</h3>
                    <p>+995 558 347 347</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-envelope"></i>
                    <h3>ელ.ფოსტა</h3>
                    <p>info@sent.ge<br>contact@sent.com</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-clock"></i>
                    <h3>სამუშაო საათები</h3>
                    <p>ორშაბათი - პარასკევი<br>10:00AM - 06:00PM</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-6">
              <form action="#" method="post" class="php-email-form">
                <div class="row gy-4">

                  <div class="col-md-6">
                    <input type="text" name="name" class="form-control" placeholder="სახელი" required>
                  </div>

                  <div class="col-md-6 ">
                    <input type="email" class="form-control" name="email" placeholder="ელ.ფოსტა" required>
                  </div>

                  <div class="col-md-12">
                    <input type="text" class="form-control" name="subject" placeholder="სათაური" required>
                  </div>

                  <div class="col-md-12">
                    <textarea class="form-control" name="message" rows="6" placeholder="შეტყობინება" required></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">Your message has been sent. Thank you!</div>

                    <button type="submit">გაგზავნა</button>
                  </div>

                </div>
              </form>

            </div>

          </div>

        </div>

      </section><!-- End Contact Section -->

    </main><!-- End #main -->
    <Footer/>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Slider from '@/components/Slider.vue'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Slider
  },
  data(){
    return{
      responseblogdata:{},
      responsepartnerdata:{}
    }
  },
  methods: {
    blogApi(){
      axios.get("https://app.sent.ge/api/public/get_all_articles")
          .then(response => this.responseblogdata = response.data)
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
    },
    PartnerApi(){
      axios.get("https://app.sent.ge/api/public/get_all_partners")
          .then(response => this.responsepartnerdata = response.data)
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
    }
  },
  mounted() {
  this.blogApi();
  this.PartnerApi();


    (function() {
      "use strict";

      /**
       * Easy selector helper function
       */

      const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }


      /**
       * Easy event listener function
       */
      const on = (type, el, listener, all = false) => {
        if (all) {
          select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
          select(el, all).addEventListener(type, listener)
        }
      }

      /**
       * Easy on scroll event listener
       */
      const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
      }

      /**
       * Navbar links active state on scroll
       */
      let navbarlinks = select('#navbar .scrollto', true)
      const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
          if (!navbarlink.hash) return
          let section = select(navbarlink.hash)
          if (!section) return
          if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active')
          } else {
            navbarlink.classList.remove('active')
          }
        })
      }
      window.addEventListener('load', navbarlinksActive)
      onscroll(document, navbarlinksActive)

      /**
       * Scrolls to an element with header offset
       */
      const scrollto = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight

        if (!header.classList.contains('header-scrolled')) {
          offset -= 10
        }

        let elementPos = select(el).offsetTop
        window.scrollTo({
          top: elementPos - offset,
          behavior: 'smooth'
        })
      }

      /**
       * Toggle .header-scrolled class to #header when page is scrolled
       */
      let selectHeader = select('#header')
      if (selectHeader) {
        const headerScrolled = () => {
          if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled')
          } else {
            selectHeader.classList.remove('header-scrolled')
          }
        }
        window.addEventListener('load', headerScrolled)
        onscroll(document, headerScrolled)
      }

      /**
       * Back to top button
       */
      let backtotop = select('.back-to-top')
      if (backtotop) {
        const toggleBacktotop = () => {
          if (window.scrollY > 100) {
            backtotop.classList.add('active')
          } else {
            backtotop.classList.remove('active')
          }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
      }

      /**
       * Mobile nav toggle
       */
      on('click', '.mobile-nav-toggle', function(e) {
        select('#navbar').classList.toggle('navbar-mobile')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
      })

      /**
       * Mobile nav dropdowns activate
       */
      on('click', '.navbar .dropdown > a', function(e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      }, true)

      /**
       * Scrool with ofset on links with a class name .scrollto
       */
      on('click', '.scrollto', function(e) {
        if (select(this.hash)) {
          e.preventDefault()

          let navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      }, true)

      /**
       * Scroll with ofset on page load with hash links in the url
       */
      window.addEventListener('load', () => {
        if (window.location.hash) {
          if (select(window.location.hash)) {
            scrollto(window.location.hash)
          }
        }
      });

      /**
       * Clients Slider
       */
      new Swiper('.clients-slider', {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 60
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 80
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 120
          }
        }
      });

      /**
       * Porfolio isotope and filter
       */
      window.addEventListener('load', () => {
        let portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
          let portfolioIsotope = new Isotope(portfolioContainer, {
            itemSelector: '.portfolio-item',
            layoutMode: 'fitRows'
          });

          let portfolioFilters = select('#portfolio-flters li', true);

          on('click', '#portfolio-flters li', function(e) {
            e.preventDefault();
            portfolioFilters.forEach(function(el) {
              el.classList.remove('filter-active');
            });
            this.classList.add('filter-active');

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter')
            });
            aos_init();
          }, true);
        }

      });

      /**
       * Initiate portfolio lightbox
       */
      const portfolioLightbox = GLightbox({
        selector: '.portfokio-lightbox'
      });

      /**
       * Portfolio details slider
       */
      new Swiper('.portfolio-details-slider', {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      });

      /**
       * Testimonials slider
       */
      new Swiper('.testimonials-slider', {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 40
          },

          1200: {
            slidesPerView: 3,
          }
        }
      });

      /**
       * Animation on scroll
       */
      function aos_init() {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          once: true,
          mirror: false
        });
      }
      window.addEventListener('load', () => {
        aos_init();
      });

    })();


  }
}
</script>
