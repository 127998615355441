<template>
 <div>
   <!-- ======= Header ======= -->
   <header id="header" class="header fixed-top">
     <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

       <a href="/" class="logo d-flex align-items-center">
        <img src="../assets/logo_sent_ge.png" class="logo-img-src-height">

       </a>

       <nav id="navbar" class="navbar">
         <ul>
           <li><a class="nav-link scrollto active" href="/">მთავარი</a></li>
           <li><a class="nav-link scrollto" href="#pricing">პაკეტები</a></li>
           <li><a class="nav-link scrollto" href="https://app.sent.ge/signin">ავტორიზაცია</a></li>
           <li><a class="getstarted scrollto" href="https://app.sent.ge/signup">რეგისტრაცია</a></li>
         </ul>
         <i class="bi bi-list mobile-nav-toggle"></i>
       </nav><!-- .navbar -->

     </div>
   </header><!-- End Header -->



 </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
export default {
  name: "Header",
  components:{
    Slider
  }
}
</script>

<style scoped>

</style>